<template>
  <div>
    <b-card title="Section FAQ">
      <div class="d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1 text-uppercase">Catégorie</label>
            <b-form-select
              v-model="currentCategory"
              :options="options"
            />
          </div>
        </b-form-group>
      </div>
      <b-table
        hover
        responsive
        show-empty
        empty-text="Aucun détail"
        :items="items"
        :fields="fields"
        :busy="loaded"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>
        <template v-slot:cell(created)="data">
          {{ data.item.created_at | moment("DD/MM/YY HH:mm") }}
        </template>
        <template v-slot:cell(updated)="data">
          {{ data.item.updated_at | moment("DD/MM/YY HH:mm") }}
        </template>
        <template v-slot:cell(action)="data">
          <span class="whitespace-no-wrap">
            <feather-icon
              icon="EditIcon"
              svg-classes="w-5 h-5 hover:text-success stroke-current"
              class="text-warning"
              @click="openModal(data.item)"
            />
          </span>
          <span class="whitespace-no-wrap">
            <feather-icon
              icon="TrashIcon"
              svg-classes="w-5 h-5 hover:text-danger stroke-current"
              class="text-danger ml-1"
              @click="openDeleteModal(data.item)"
            />
          </span>
        </template>
      </b-table>
      <div class="mt-2">
        <div class="float-left">
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
          />
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="right"
        />
      </div>
    </b-card>
    <b-modal
      v-if="showModal"
      v-model="showModal"
      centered
      hide-footer
      title="Modification"
      :no-close-on-backdrop="process"
      :hide-header-close="process"
      :busy="process"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group label="Question">
              <b-form-input
                v-model="$v.currentDetails.question.$model"
                :class="{ 'is-invalid': $v.currentDetails.question.$error }"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
          >
            <b-form-group label="Réponse">
              <b-form-input
                v-model="$v.currentDetails.response.$model"
                :class="{ 'is-invalid': $v.currentDetails.response.$error }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div
              v-if="process"
              class="d-flex justify-content-center mt-1"
            >
              <b-spinner
                variant="primary"
                label="Loading..."
              />
            </div>
            <div class="mt-3">
              <b-button
                type="submit"
                variant="outline-primary"
                class="ml-2 float-right"
                :disabled="process"
                @click="tryToUpdate"
              >
                Modifier
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      centered
      hide-footer
      title="Suppression"
      :no-close-on-backdrop="delProcess"
      :hide-header-close="delProcess"
      :busy="delProcess"
    >
      <div>
        <div class="p-2">
          Voulez-vous supprimer le détail du système ?
        </div>
        <b-row>
          <b-col cols="12">
            <div
              v-if="delProcess"
              class="d-flex justify-content-center mt-1"
            >
              <b-spinner
                variant="danger"
                label="Loading..."
              />
            </div>
            <div class="mt-3">
              <b-button
                type="submit"
                variant="outline-danger"
                class="ml-2 float-right"
                :disabled="delProcess"
                @click="tryToDelete"
              >
                Supprimer
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      items: [],
      currentPage: 1,
      currentCategory: null,
      loaded: false,
      perPage: 10,
      totalItems: 0,
      showModal: false,
      showDeleteModal: false,
      pageOptions: [5, 10, 15],
      allSections: [],
      currentDetails: {
        id: null,
        question: null,
        response: null,
      },
      fields: [
        { key: 'question', label: 'Question' },
        { key: 'response', label: 'Réponse' },
        { key: 'created', label: 'Ajouté' },
        { key: 'updated', label: 'Modifié' },
        { key: 'action', label: 'Action' },
      ],
    }
  },
  validations: {
    currentDetails: {
      id: {
        required,
      },
      question: {
        required,
      },
      response: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'updateFaqDetailsSuccess',
      error: 'updateFaqDetailsError',
      process: 'updateFaqDetailsProcess',

      delSuccess: 'deleteFaqDetailsSuccess',
      delError: 'deleteFaqDetailsError',
      delProcess: 'deleteFaqDetailsProcess',
    }),
    options() {
      const options = this.allSections
      const data = [
        {
          value: null,
          text: 'Choisir la catégorie',
        },
      ]
      if (options.length > 0) {
        options.forEach(item => {
          const current = {
            value: item.id,
            text: item.section,
          }
          data.push(current)
        })
      }
      return data
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllSections()
      },
    },
    currentCategory() {
      this.getAllFaqDetails()
    },
    delSuccess(val) {
      if (val) {
        this.closeDeleteModal()
        this.getAllFaqDetails()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression du détail',
            text: 'Supprimé avec succès',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    delError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression du détail',
            text: 'Une erreur est survenue',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    success(val) {
      if (val) {
        this.closeModal()
        this.getAllFaqDetails()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Modification de la section',
            text: 'Modifié avec succes',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Modification de la section',
            text: 'Une erreur est survenue',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['updateFaqDetails', 'deleteFaqDetails']),
    formatter(value) {
      return value.toUpperCase()
    },
    async getAllSections() {
      this.allSections = await this.$http
        .get('/faq/all',
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => {
          const { data } = res.data
          return data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Détails FAQ',
              text: 'Une erreur est survenue lors de la récupération',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          return []
        })
    },
    resetForm() {
      this.section = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    openModal(item) {
      this.currentDetails = {
        id: item.id,
        question: item.question,
        response: item.response,
      }
      this.showModal = true
    },
    closeModal() {
      this.currentDetails = null
      this.showModal = false
    },
    openDeleteModal(item) {
      this.currentDetails.id = item.id
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.currentDetails = null
      this.showDeleteModal = false
    },
    tryToUpdate() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      const body = {
        question: this.currentDetails.question,
        response: this.currentDetails.response,
        faq_section_id: this.currentCategory,
      }
      this.updateFaqDetails({
        id: this.currentDetails.id,
        body,
      })
    },
    tryToDelete() {
      this.deleteFaqDetails(this.currentDetails.id)
    },
    async getAllFaqDetails() {
      this.loaded = true
      this.items = await this.$http
        .get(`/faq-by-id/${this.currentCategory}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => {
          const { data } = res
          this.loaded = false
          this.totalItems = data.details.length
          return data.details
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    },
  },
}
</script>

<style>

</style>
